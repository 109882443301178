export const LINKS = [
	{
		link: '#begin',
		name: 'Inicio'
	},
	{
		link: '#services',
		name: 'Reserva'
	},
	{
		link: '#location',
		name: 'Ubicación'
	},
	{
		link: '#contact',
		name: 'Contacto'
	}
];
