'use client';
import { useState, useEffect } from 'react';
import { useAnimate } from 'framer-motion';
import { MenuToggle } from './MenuToggle';
import { useMediaQuery } from 'react-responsive';
import NavBar from './NavBar';
import '@/styles/layout/header.scss';
function useMenuAnimation(isOpen: any) {
	const [scope, animate] = useAnimate();

	useEffect(() => {
		animate([
			['path.top', { d: isOpen ? 'M 3 16.5 L 19 2.5' : 'M 2 2.5 L 20 2.5' }, { at: '<' }],
			['path.middle', { opacity: isOpen ? 0 : 1 }, { at: '<' }],
			['path.bottom', { d: isOpen ? 'M 3 2.5 L 19 16.346' : 'M 2 16.346 L 20 16.346' }, { at: '<' }]
		]);
	}, [isOpen]);

	return scope;
}

export function Header() {
	const isMobile: boolean = useMediaQuery({ maxWidth: 640 });
	const [isOpen, setIsOpen] = useState(false);
	const [isSticky, setIsSticky] = useState(false);
	const scope = useMenuAnimation(isOpen);
	useEffect(function () {
		const targetHero = document.querySelector('.hero');
		const observer = new IntersectionObserver(
			(entries) => {
				if (!entries) return;
				entries.forEach((entry) => {
					if (!entry.isIntersecting) {
						setIsSticky(true);
					} else {
						setIsSticky(false);
					}
				});
			},
			{
				rootMargin: '-84px'
			}
		);
		observer.observe(targetHero as Element);
	}, []);
	return (
		<header className={`header ${isOpen ? 'open' : ''}${isSticky || isOpen ? ' sticky' : ''}`} ref={scope}>
			<div className="header__container">
				<NavBar isOpen={isOpen} setIsOpen={setIsOpen} isMobile={isMobile} />
				<MenuToggle toggle={() => setIsOpen(!isOpen)} />
			</div>
		</header>
	);
}
