'use client';

import React, { createContext, useState, ReactNode, Dispatch, SetStateAction, useContext } from 'react';

interface FirstLoadContextType {
	isFirstLoad: boolean;
	setIsFirstLoad: Dispatch<SetStateAction<boolean>>;
}

export const FirstLoadContext = createContext<FirstLoadContextType | undefined>(undefined);

function FirstLoadContextProvider({ children }: { children: ReactNode }) {
	const [isFirstLoad, setIsFirstLoad] = useState(true);

	return <FirstLoadContext.Provider value={{ isFirstLoad, setIsFirstLoad }}>{children}</FirstLoadContext.Provider>;
}

export default FirstLoadContextProvider;

export const useFirstLoad = (): FirstLoadContextType => {
	const context = useContext(FirstLoadContext);
	if (!context) {
		throw new Error('useFirstLoad must be used within a FirstLoadContextProvider');
	}
	return context;
};
