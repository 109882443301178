'use client';
import { useEffect, useRef } from 'react';
import Logo from './Logo';

import { LINKS } from './links';
import { BONUS_LINKS } from './bonusLinks';

function NavBar({ setIsOpen, isOpen, isMobile }: any) {
	function handleClick() {
		setIsOpen(false);
	}

	useEffect(() => {
		if (isOpen && isMobile) {
			let firstMenuItem = document.querySelector('.menu__link--0') as HTMLElement;
			setTimeout(() => {
				firstMenuItem.focus();
			}, 200);
		}
	}, [isOpen, isMobile]);

	return (
		<>
			<Logo />
			<nav className="menu">
				<div className="menu__outside" onClick={handleClick}></div>
				<div>
					<span className="menu__span">NAVEGACIÓN</span>
					<ul>
						{LINKS.map(({ link, name }, i) => (
							<li key={name}>
								<a className={`menu__link--${i}`} tabIndex={isOpen ? 0 : -1} href={link} onClick={handleClick}>
									<span>{name}</span>
								</a>
							</li>
						))}
					</ul>
				</div>
				<div className="bonus">
					<span className="menu__span">Redes</span>
					<ul>
						{BONUS_LINKS.map(({ link, name }) => (
							<li key={name}>
								<a tabIndex={isOpen ? 0 : -1} href={link} target="_blank">
									<span>{name}</span>
								</a>
							</li>
						))}
					</ul>
				</div>
			</nav>
		</>
	);
}

export default NavBar;
