'use client';

import { ReactNode, useEffect, useState, useRef } from 'react';
import { Spinner } from './spinner';
import { useFirstLoad } from '@/contexts/FirstLoadContext';

function FirstLoadHandler({ children }: { children: ReactNode }) {
	const [isLoading, setIsLoading] = useState(true);
	const [isMounted, setIsMounted] = useState(true);
	const loaderRef = useRef<HTMLDivElement>(null);
	const { setIsFirstLoad } = useFirstLoad();

	useEffect(() => {
		const handleLoad = () => {
			setIsLoading(false);
			setIsFirstLoad(false);
		};

		if (document.readyState === 'complete') {
			// Si la página ya ha cargado completamente
			handleLoad();
		} else {
			// Escuchar el evento 'load' del window
			window.addEventListener('load', handleLoad);
		}

		return () => {
			window.removeEventListener('load', handleLoad);
		};
	}, [setIsFirstLoad]);

	useEffect(() => {
		if (!isLoading && loaderRef.current) {
			const currentLoader = loaderRef.current;
			const handleTransitionEnd = () => {
				setIsMounted(false);
			};
			loaderRef.current.addEventListener('transitionend', handleTransitionEnd);

			// Tiempo de espera adicional por si la transición no se dispara
			const fallbackTimeout = setTimeout(() => {
				setIsMounted(false);
			}, 1000); // 1 segundo

			return () => {
				currentLoader.removeEventListener('transitionend', handleTransitionEnd);
				clearTimeout(fallbackTimeout);
			};
		}
	}, [isLoading]);

	return (
		<>
			{isMounted && (
				<div
					ref={loaderRef}
					style={{
						position: 'fixed',
						top: 0,
						left: 0,
						width: '100%',
						height: '100%',
						backgroundColor: '#131313',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						zIndex: 9999,
						opacity: isLoading ? 1 : 0,
						transition: 'opacity 0.4s'
					}}
					role="status"
					aria-live="polite"
					aria-label="Cargando">
					<Spinner />
				</div>
			)}
			{children}
		</>
	);
}

export default FirstLoadHandler;
